import Alpine from 'alpinejs';
import Webcam from 'webcam-easy';

window.Alpine = Alpine;
window.Webcam = Webcam;

window.scrollToElement = (id) => {
    const position = document.getElementById(id)?.getBoundingClientRect();

    window.scrollTo({
        top: position.y,
        behavior: 'smooth',
    });
};

Alpine.start();
